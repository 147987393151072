@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

:root {
  --vh: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.padding {
  padding: 1rem;
}

.chat-fullwidth {
  width: 100%;
  max-width: 800px;
}
